import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { CheckboxInput, LoadingIndicator, Search } from 'lib/components';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../../types';
import { useUsersResellersQuery } from 'lib/api/superadmin/useResellersUsersQuery';
import { difference, intersection, keyBy, uniq } from 'lodash';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { DepartmentsCheckboxDropdown } from 'app/pages/reports/components';

const Content = styled.div`
  margin-top: 32px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff2;
  padding-bottom: 20px;
`;

const SelectionCountWrapper = styled.div`
  display: flex;
  max-width: 500px;
  flex: 1;
`;

const SelectionCountText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const Counter = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  margin-left: 16px;
`;

const UsersList = styled.div`
  height: calc(100vh - 350px);
  overflow-y: auto;
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
`;

const UserTitle = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral[100]};
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
`;

const UserMoreInfo = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral[80]};
`;

const Message = styled.p`
  font-family: Work Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin: 0 0 32px 0;
  color: ${({ theme }) => theme.colors.neutral[80]};
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

export const SelectCustomReportUsers = () => {
  const {
    userData: { customerId: currentCustomerId, resellerId, isCompanyAdmin },
  } = useAuth();
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  const { data, isLoading } = useUsersResellersQuery({
    params: {
      resellerId,
      customerId: currentCustomerId,
    },
    enabled: isCompanyAdmin,
  });

  // map over response and get users
  const usersResponse = data?.users.map(user => ({
    ...user,
    id: `${user.id}`,
  }));

  // users to be shown
  const filteredData = usersResponse
    ?.filter(user =>
      `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
        searchQuery.toLowerCase()
      )
    )
    .filter(
      user =>
        selectedDepartments.length === 0 ||
        selectedDepartments.some(dept => `${dept}` === `${user.departmentId}`)
    );

  const onSearch = (query: string = '') => {
    setSearchQuery(query);
  };

  const toggleUser = (userId: string) => {
    const updatedUsers = values.users.includes(userId)
      ? values.users.filter(id => id !== userId) // Remove userId from selected if it exists
      : [...values.users, userId];
    setFieldValue('users', updatedUsers);
  };

  const allUserIds: string[] = usersResponse?.map(user => user.id) || [];
  const filteredUsersIds: string[] = filteredData?.map(user => user.id) || [];

  //get same ids from both array values.users and filteredData to toggle select all
  const commonElements = intersection(values.users, filteredUsersIds);
  const isAllSelected = commonElements.length === filteredUsersIds.length;

  const toggleSelectAll = () => {
    const updatedUsers = isAllSelected
      ? difference(values.users, filteredUsersIds) // Deselect all users if all are currently selected
      : uniq([...filteredUsersIds, ...(values.users || [])]);

    setFieldValue('users', updatedUsers);
  };

  const toggleFutureUsers = () => {
    setFieldValue('includeNewUsers', !values.includeNewUsers);
  };

  const departments =
    data?.departments.map(department => ({
      value: `${department.id}`,
      label: department.name,
    })) || [];

  const departmentsDictionary = keyBy(departments, 'value');

  return (
    <Content>
      {isLoading ? (
        <LoadingIndicator isLoading={true} height='300px' />
      ) : (
        <>
          <Message>
            Select users whose data will be included in the report
          </Message>
          <Menu>
            <SelectionCountWrapper>
              <CheckboxInput
                checked={filteredUsersIds.length === 0 ? false : isAllSelected}
                onChange={toggleSelectAll}
                disabled={isLoading || filteredUsersIds.length === 0}
              />
              <SelectionCountText>
                <div>Users selected:</div>
                <Counter>
                  {values.users.length} / {allUserIds.length}
                </Counter>
              </SelectionCountText>
            </SelectionCountWrapper>
            <SelectionCountWrapper>
              <CheckboxInput
                checked={!!values.includeNewUsers}
                onChange={toggleFutureUsers}
              />
              <SelectionCountText>
                <div style={{ fontWeight: 'bold' }}>Include New Users</div>
              </SelectionCountText>
            </SelectionCountWrapper>
            <div style={{ width: '300px', paddingRight: '8px' }}>
              <Search placeholder='Search users...' onSearch={onSearch} />
            </div>
            {!!departments.length && (
              <DepartmentsCheckboxDropdown
                departments={departments}
                menuPortalTarget={null}
                menuShouldBlockScroll={false}
                onDepartmentsSelected={departments => {
                  setSelectedDepartments(departments);
                }}
              />
            )}
          </Menu>
          <UsersList>
            {filteredData?.length === 0 ? (
              <EmptySearch>
                <ParagraphSmallBold
                  color={theme.palette.blue60}
                  style={{ width: 485, textWrap: 'initial' }}
                >
                  We couldn't find any users with the name '{searchQuery}' in
                  their first or last names. Please try searching with a
                  different name.
                </ParagraphSmallBold>
              </EmptySearch>
            ) : (
              filteredData?.map(user => {
                const { departmentId, id, firstName, lastName, email } = user;
                const departmentName =
                  departmentsDictionary[departmentId]?.label || '';

                return (
                  <UserItem key={id}>
                    <Gap
                      gap='8px'
                      style={{
                        flex: 1,
                      }}
                    >
                      <CheckboxInput
                        checked={values.users.includes(id)}
                        onChange={() => {
                          toggleUser(id);
                        }}
                      />

                      <UserTitle
                        onClick={() => {
                          toggleUser(id);
                        }}
                      >
                        {`${firstName} ${lastName}`}
                      </UserTitle>
                    </Gap>
                    <UserMoreInfo style={{ flex: 1, display: 'flex' }}>
                      {email}
                    </UserMoreInfo>
                    <UserMoreInfo
                      style={{
                        width: 200,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {departmentName}
                    </UserMoreInfo>
                  </UserItem>
                );
              })
            )}
          </UsersList>
        </>
      )}
    </Content>
  );
};
