import React from 'react';
import { Breadcrumbs } from 'app/pages/admin/index.styled';
import { MdOutlineChevronRight } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const Breadcrumb = styled.button<{
  isDisabled?: boolean;
}>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: ${props => (props.isDisabled ? 'none' : 'underline')};
  display: inline;
  margin: 0;
  padding: 0;
  color: ${props =>
    props.isDisabled ? theme.palette.gray80 : theme.palette.covideoBlue100};
  font-weight: 500;
  font-size: 15px;
  cursor: ${props => (props.isDisabled ? 'auto' : 'pointer')};
  white-space: ${props => (props.isDisabled ? 'nowrap' : 'none')};
  overflow: ${props => (props.isDisabled ? 'hidden' : 'none')};
  text-overflow: ${props => (props.isDisabled ? 'ellipsis' : 'none')};
`;

type Props = {
  styles?: object;
  items: {
    path: string | null;
    name: string;
    isDisabled: boolean;
  }[];
};

const GuideBreadcrumbs = ({ items, styles }: Props) => {
  const history = useHistory();
  return (
    <Breadcrumbs style={styles}>
      {items.map((item, index) => (
        <>
          <Breadcrumb
            isDisabled={!!item.isDisabled}
            onClick={() => {
              if (item.path === null) {
                return;
              }
              history.push(item.path);
            }}
          >
            {item.name}
          </Breadcrumb>
          {items.length - 1 !== index && (
            <MdOutlineChevronRight
              size={16}
              color={theme.palette.covideoBlue100}
              style={{ padding: '0px 7px 0px 8px' }}
            />
          )}
        </>
      ))}
    </Breadcrumbs>
  );
};

export default GuideBreadcrumbs;
