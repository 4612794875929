import React from 'react';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { HeaderContainer } from '../../components/styles';
import {
  LoadingIndicator,
  TextInput,
  Dropdown,
  ModalDelete,
} from 'lib/components';
import { downloadVideo } from 'lib/api/videosApi';
import {
  GuideDetails,
  useGuideDetailsQuery,
} from 'lib/api/guides/guideDetailsQuery';
import {
  InputLabel,
  InputPlaceholder,
  InputPlaceholderText,
  InputWrapper,
  ItemContainer,
  ItemsWrapper,
} from 'app/pages/video/videoDetails/styles/layout';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { DeleteGuideModal } from '../../modals/DeleteGuideModal';
import { Button, Tooltip } from 'react-covideo-common';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { Header } from 'app/pages/video/videoDetails/components/AttributesForm';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  ErrorMessage,
} from 'formik';
import {
  EditableGuides,
  useEditableGuideCategoriesQuery,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import { useEditGuideMutation } from 'lib/api/guides/editGuideMutation';
import {
  Label,
  LinksHeader,
  Input as ExtendedInput,
  FieldWrapper,
  LinkItem,
} from 'app/pages/design/callsToAction/InteractiveCTAs';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import ReplaceGuideModal from '../../modals/ReplaceGuideModal';
import GuideBreadcrumbs from '../../components/GuideBreadcrumbs';
import { wrapWithHttp } from 'lib/utils/functions';
import { URL_REGEX } from 'lib/utils/regexes';
import { useHistory } from 'react-router';
import { errorToast } from 'lib/components/toasts/error';
import {
  ErrorMessage as CustomErrorMessage,
  ErrorMessageText,
  TextArea,
} from './styles';
import AddNewDropModal from 'app/pages/library/components/AddNewDropModal';
import { getImageFileExtensions } from 'lib/utils/files';
import { VideoTags } from 'app/pages/video/videoDetails/components/VideoTags';
import { useGetVideoTags } from 'lib/api/videoTags/getVideoTags';
import { VideoEmailStatus } from 'lib/const';
import { useUpdateVideoTags } from 'lib/api/videoTags/updateVideoTags';
import { IoMdClose, IoMdLink, IoMdSave } from 'react-icons/io';
import {
  MdDeleteForever,
  MdDownload,
  MdEdit,
  MdOpenInNew,
  MdSave,
  MdUpload,
} from 'react-icons/md';
import { GuideTitleHeading } from './GuideDetailsPage';

interface IDropdown {
  value: number;
  label: string;
}

const regex = new RegExp(URL_REGEX);

const manageGuideSchema = Yup.object({
  links: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required('Display name is required'),
        link: Yup.string()
          .required('URL is required')
          .matches(regex, 'Invalid URL'),
      })
    )
    .notRequired()
    .nullable(true),
  description: Yup.string()
    .max(1000, 'Description has a maximum limit of 1000 characters.')
    .notRequired()
    .nullable(true),
  guideCategoryId: Yup.number().required(),
});

const RequiredIcon = styled.span`
  color: ${({ theme }) => theme.colors.primary[100]};
  :after {
    content: '*';
  }
`;

const CustomThumbnailWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  background-color: ${theme.palette.blue02};
  height: 168px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${theme.palette.gray20};
  margin-top: 24px;
`;

const CustomThumbnailButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const CustomThumbnailContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 0px 16px 24px;
  flex: 1;
  flex-basis: 50%;
  max-width: 50%;
  span {
    color: ${theme.palette.neutral80};
    font-size: 14px;
    font-weight: 500;
  }
`;

const CustomThumbnail = styled.img`
  width: 225px;
  height: 128px;
  object-fit: cover;

  border-radius: 4px;
  border: 1px solid var(--black-10, rgba(39, 42, 50, 0.1));
`;

const CustomThumbnailImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 24px;
`;
export const GuideManagePage = () => {
  const history = useHistory();
  const { data, isLoading } = useGuideDetailsQuery();
  const { data: editableCategories } = useEditableGuideCategoriesQuery();
  const { mutateAsync: updateGuide, isLoading: isEditGuideSubmitting } =
    useEditGuideMutation();
  const { data: videoTags } = useGetVideoTags(data?.videoId || 0);
  const { mutateAsync: updateVideoTags } = useUpdateVideoTags(
    data?.videoId || 0
  );
  const { userData } = useAuth();
  const [editTitle, setEditTitle] = React.useState(false);
  const [showGuideDeleteModal, setShowGuideDeleteModal] = React.useState(false);
  const [replaceModalOpen, setReplaceModalOpen] = React.useState(false);
  const [uploadThumbnailModal, setUploadThumbnailModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }
  const openEditTitle = () => {
    setEditTitle(true);
  };

  const handleDownloadBtnClick = async (e?: React.SyntheticEvent) => {
    if (e) e.stopPropagation();
    const videoId = data?.videoId?.toString() || '';
    downloadVideo(videoId, `${data?.video.title}.mp4`);
  };

  const closeEditTitle = () => {
    setEditTitle(false);
  };

  const handleSubmit = async (values: Partial<GuideDetails>) => {
    closeEditTitle();
    await updateGuide({
      ...values,
      links: values.links?.map(link => ({
        ...link,
        link: wrapWithHttp(link.link),
      })),
    });
  };

  const handleDeleteThumbnail = async () => {
    await updateGuide({
      customThumbnail: null,
    });
    setShowDeleteModal(false);
  };

  if (!data) {
    errorToast({ title: 'Training does not exist.' });
    history.push('/guides');
  }

  const videoTagOptions = (videoTags || [])
    .map(tag => ({
      value: tag.tagId,
      label: tag.tag,
    }))
    .filter(Boolean);

  return (
    <React.Fragment>
      {data && (
        <GuideBreadcrumbs
          items={[
            { path: '/guides', name: 'Training', isDisabled: false },
            {
              path: `/guides/${data?.guideCategory.guideCategoryId}`,
              name: data?.guideCategory?.name || '',
              isDisabled: false,
            },
            {
              path: null,
              name: data?.name || '',
              isDisabled: true,
            },
          ]}
        />
      )}
      <Formik
        initialValues={{
          name: data?.name,
          description: data?.description,
          guideCategoryId: data?.guideCategoryId,
          links: data?.links,
        }}
        validationSchema={manageGuideSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, isValid, dirty }) => {
          return (
            <Form>
              <HeaderContainer style={{ paddingTop: '0px' }}>
                {editTitle ? (
                  <Field name='name'>
                    {({ field }: FieldProps) => {
                      return (
                        <TextInput
                          {...field}
                          type='text'
                          placeholder='Training Video Name'
                          required={true}
                          disabled={isSubmitting}
                          width={'70%'}
                        />
                      );
                    }}
                  </Field>
                ) : (
                  <Tooltip
                    extendStyles={{
                      popup: {
                        padding: '10px',
                        maxWidth: 700,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        overflow: 'hidden',
                      },
                    }}
                    popup={<>{data?.name}</>}
                  >
                    <GuideTitleHeading>{data?.name}</GuideTitleHeading>
                  </Tooltip>
                )}
                <Gap gap='0'>
                  {!editTitle && (
                    <Gap gap='7px'>
                      <Button
                        onClick={openEditTitle}
                        text=''
                        variant='ghost'
                        icon={
                          <MdEdit
                            color={theme.palette.covideoBlue100}
                            size={'20px'}
                          />
                        }
                      />

                      <Button
                        onClick={handleDownloadBtnClick}
                        text=''
                        variant='ghost'
                        icon={
                          <MdDownload
                            color={theme.palette.covideoBlue100}
                            size={'20px'}
                          />
                        }
                      />

                      {userData.allowDelete && (
                        <Button
                          text=''
                          icon={
                            <MdDeleteForever
                              size='20px'
                              color={theme.palette.buttonDelete}
                            />
                          }
                          onClick={() => {
                            setShowGuideDeleteModal(true);
                          }}
                          variant='destructive'
                        />
                      )}

                      <Button
                        onClick={() => setReplaceModalOpen(true)}
                        text='Reupload Video'
                        variant='secondary'
                        icon={<MdUpload size={18} />}
                      />

                      <Button
                        type='submit'
                        text='Save Changes'
                        variant='primary'
                        icon={<IoMdSave size={18} />}
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !dirty ||
                          isEditGuideSubmitting
                        }
                      />
                    </Gap>
                  )}

                  {editTitle && (
                    <Gap flexWrap='nowrap'>
                      <Button
                        variant='secondary'
                        icon={<IoMdClose size={20} />}
                        onClick={closeEditTitle}
                      />
                      <Button
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !dirty ||
                          isEditGuideSubmitting
                        }
                        type='submit'
                        text='Save Training Video Name'
                        variant='primary'
                        icon={<MdSave size={'18px'} />}
                      />
                    </Gap>
                  )}
                </Gap>
              </HeaderContainer>
              <ItemsWrapper>
                <ItemContainer>
                  <Gap gap='12px'>
                    <InputWrapper style={{ width: '48%' }}>
                      <InputLabel>Date Created</InputLabel>
                      <InputPlaceholder minWidth='180px'>
                        <InputPlaceholderText>
                          {data?.createdAt.toString().substring(0, 10)}
                        </InputPlaceholderText>
                      </InputPlaceholder>
                    </InputWrapper>
                    <InputWrapper style={{ width: '48%' }}>
                      <InputLabel>Time Created</InputLabel>
                      <InputPlaceholder minWidth='180px'>
                        <InputPlaceholderText>
                          {data?.createdAt.toString().substring(11, 19)}
                        </InputPlaceholderText>
                      </InputPlaceholder>
                    </InputWrapper>
                  </Gap>
                  <Gap
                    flexDirection='column'
                    alignItems='stretch'
                    gap='32px'
                    style={{ marginTop: '32px' }}
                  >
                    <Header>Details</Header>
                    <InputWrapper>
                      <InputLabel>Description</InputLabel>
                      <Field
                        name='description'
                        as={TextArea}
                        maxRowLength={66}
                        textLength={
                          !!values.description
                            ? (values.description as string).length
                            : 0
                        }
                      />
                      <ErrorMessage
                        name='description'
                        component={ErrorMessageText}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <InputLabel>Category</InputLabel>
                      <Field name='guideCategoryId'>
                        {({ field, form }: FieldProps) => {
                          const { value } = field;
                          const options = editableCategories?.map(
                            (category: EditableGuides) => ({
                              value: category.guideCategoryId,
                              label: category.name,
                            })
                          );
                          return (
                            <Dropdown
                              options={options}
                              creatable={false}
                              value={options?.find(
                                option => option.value === value
                              )}
                              onChange={(option: IDropdown) => {
                                form.setFieldValue(
                                  'guideCategoryId',
                                  option.value
                                );
                              }}
                              isSearchable={true}
                              placeholder={'Select Training Video Category'}
                              disabled={isSubmitting}
                            />
                          );
                        }}
                      </Field>
                    </InputWrapper>
                  </Gap>
                  <Gap
                    flexDirection='column'
                    alignItems='stretch'
                    gap='22px'
                    style={{ marginTop: '32px' }}
                  >
                    <Header style={{ height: '12px' }}>
                      Assigned Articles & Links
                    </Header>
                    <InputWrapper>
                      {!!values?.links?.length && (
                        <LinksHeader>
                          <Label width='178px'>
                            Display Name <RequiredIcon />
                          </Label>
                          <Label width='198px'>
                            URL <RequiredIcon />
                          </Label>
                          <Label width='84px'>Preview</Label>
                        </LinksHeader>
                      )}
                      <FieldArray
                        name='links'
                        render={arrayHelpers => (
                          <>
                            {values?.links?.map((ctaValue, index: number) => (
                              <LinkItem key={index} height='auto'>
                                <FieldWrapper width={`178px`}>
                                  <Field name={`links[${index}].label`}>
                                    {({ field, meta }: FieldProps) => (
                                      <>
                                        <ExtendedInput
                                          {...field}
                                          type='text'
                                          placeholder='Display Name'
                                          maxLength={35}
                                          hasError={
                                            meta.touched && !!meta.error
                                          }
                                        />
                                        {meta.touched && meta.error && (
                                          <CustomErrorMessage>
                                            {meta.error}
                                          </CustomErrorMessage>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FieldWrapper>
                                <FieldWrapper width={`186px`}>
                                  <Field name={`links[${index}].link`}>
                                    {({ field, meta }: FieldProps) => (
                                      <>
                                        <ExtendedInput
                                          {...field}
                                          type='text'
                                          placeholder='URL'
                                          maxLength={35}
                                          hasError={
                                            meta.touched && !!meta.error
                                          }
                                        />
                                        {meta.touched && meta.error && (
                                          <CustomErrorMessage>
                                            {meta.error}
                                          </CustomErrorMessage>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </FieldWrapper>

                                <FieldWrapper width='46px'>
                                  <Button
                                    text=''
                                    icon={<MdOpenInNew size='18px' />}
                                    variant='secondary'
                                    onClick={() => {
                                      window.open(ctaValue.link, '_blank');
                                    }}
                                    disabled={!ctaValue.link}
                                  />
                                </FieldWrapper>

                                <FieldWrapper width='46px'>
                                  {
                                    <Button
                                      variant='destructive'
                                      text=''
                                      icon={
                                        <MdDeleteForever
                                          size='24px'
                                          color={theme.palette.buttonDelete}
                                        />
                                      }
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  }
                                </FieldWrapper>
                              </LinkItem>
                            ))}
                            <Button
                              onClick={() => {
                                arrayHelpers.push({
                                  link: '',
                                  label: '',
                                });
                              }}
                              text='New Link'
                              variant='secondary'
                              icon={
                                <IoMdLink
                                  color={theme.palette.covideoBlue100}
                                  size={'20px'}
                                />
                              }
                              disabled={
                                values.links
                                  ? values?.links?.length >= 10
                                  : false
                              }
                            />
                            {values.links && values?.links?.length >= 10 && (
                              <ParagraphSmallBold
                                style={{ color: theme.palette.red100 }}
                              >
                                This video guide can’t have any more assigned
                                articles and links.
                              </ParagraphSmallBold>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>
                  </Gap>
                  <VideoTags
                    videoTags={videoTagOptions}
                    onUpdate={updateVideoTags}
                    emailStatus={VideoEmailStatus.NotSent}
                  />
                </ItemContainer>
                <ItemContainer
                  style={{ alignItems: 'end' }}
                  width='fit-content'
                >
                  <VideoPlayer
                    playerBackgroundColor={data?.video.playerBackgroundColor}
                    videoSource={data?.video.videoSource || ''}
                    videoId={data?.video.id.toString()}
                    playButtonPosition={data?.video.playButtonPosition}
                    playerIconsColor={data?.video.playerIconsAndTextColor}
                    videoRef={React.createRef()}
                    thumbnail={data?.customThumbnail || data?.video.thumbnail}
                    height='280px'
                    videoChapters={data?.video?.chapters?.map(
                      (val: { playbackPosition: number; title: string }) => ({
                        playbackPosition: val.playbackPosition,
                        title: val.title,
                      })
                    )}
                    style={{ justifyContent: 'flex-end' }}
                  />
                  {data?.customThumbnail ? (
                    <CustomThumbnailWrapper>
                      <CustomThumbnailContent>
                        <span>Thumbnail</span>
                        <Button
                          onClick={() => setUploadThumbnailModal(true)}
                          text='Replace'
                          variant='ghost'
                          icon={<MdUpload size={18} />}
                        />
                        <Button
                          onClick={() => setShowDeleteModal(true)}
                          text='Remove'
                          variant='destructive'
                          icon={
                            <MdDeleteForever
                              size={18}
                              color={theme.palette.red100}
                            />
                          }
                        />
                      </CustomThumbnailContent>
                      <CustomThumbnailImageContainer>
                        <CustomThumbnail
                          alt='Custom thumbnail'
                          src={data.customThumbnail}
                        />
                      </CustomThumbnailImageContainer>
                    </CustomThumbnailWrapper>
                  ) : (
                    <CustomThumbnailButtonWrapper>
                      <Button
                        onClick={() => setUploadThumbnailModal(true)}
                        text='Upload Custom Thumbnail'
                        variant='secondary'
                        icon={<MdUpload size={18} />}
                      />
                    </CustomThumbnailButtonWrapper>
                  )}
                </ItemContainer>
              </ItemsWrapper>
            </Form>
          );
        }}
      </Formik>
      {showGuideDeleteModal && (
        <DeleteGuideModal
          closeModalHandler={() => setShowGuideDeleteModal(false)}
          selectedGuides={[data?.videoId || 0]}
          guideCategoryId={data?.guideCategoryId || 0}
        />
      )}
      {replaceModalOpen && data?.video && (
        <ReplaceGuideModal
          video={{
            videoId: data.video.id,
            title: data.video.title || '',
          }}
          handleClose={() => setReplaceModalOpen(false)}
        />
      )}

      {uploadThumbnailModal && (
        <AddNewDropModal
          closeModal={() => setUploadThumbnailModal(false)}
          isGuide={true}
          updateGuide={updateGuide}
          acceptedFileTypes={getImageFileExtensions()}
        />
      )}

      {showDeleteModal && (
        <ModalDelete
          whiteButtonText='Yes, Delete'
          orangeButtonText='No, Cancel'
          title={`You want to remove custom thumbnail on this guide?`}
          text={`Default video guide will be used as thumbnail on this guide.`}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={handleDeleteThumbnail}
          onClickOrangeButton={() => setShowDeleteModal(false)}
          whiteButtonDisabled={isEditGuideSubmitting}
        />
      )}
    </React.Fragment>
  );
};
